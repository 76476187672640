import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/inventory/admin/InventoryReportBuilding.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

const InventoryReportBuilding = ({ search }) => {
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState("TODOS");
    const [filteredData, setFilteredData] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]); 

    const qActiveOrders = useQuery(`{
        orders {
            id
            buildingName
            status
            shippings {
                contents {
                    partId
                    partName
                }
            }
        }
    }`, "status=CHARGING OR status=IN_BUILDING OR status=TRAVELING");

    const qInventories = useQuery(`{
        inventories {
            totalParts
            inUse
            category
            part {
                name
                partId
                isManual
                craneModel
                inventoryExceptions {
                    status
                }
            }
        }
    }`);

    useEffect(() => {
        if (qActiveOrders.data?.orders) {
            const allOrders = [{ id: "TODOS", buildingName: "Todos" }, ...qActiveOrders.data.orders];
            setOrders(allOrders);
        }
    }, [qActiveOrders.data]);

    useEffect(() => {
        if (search) {
            const matchingOrders = qActiveOrders.data?.orders?.filter(order =>
                order.buildingName.toLowerCase().includes(search.toLowerCase())
            ) || [];

            setFilteredOrders([{ id: "TODOS", buildingName: "Todos" }, ...matchingOrders]);
        } else {
            setFilteredOrders(orders);
        }
    }, [search, qActiveOrders.data, orders]);

    useEffect(() => {
        if (activeTab === "TODOS") {
            setFilteredData(qInventories.data?.inventories || []);
        } else {
            const selectedOrder = qActiveOrders.data?.orders?.find(order => order.id === activeTab);

            if (selectedOrder && selectedOrder.shippings?.length > 0) {
                const partsInOrder = selectedOrder.shippings.flatMap(shipping => shipping.contents?.map(content => content.partId) || []);

                const filteredInventory = qInventories.data?.inventories?.filter(inventoryItem =>
                    partsInOrder.includes(inventoryItem.part.partId)
                );

                setFilteredData(filteredInventory || []);
            } else {
                setFilteredData([]);
            }
        }
    }, [activeTab, qActiveOrders.data, qInventories.data]);

    if (qActiveOrders.loading || qInventories.loading) return <Loading />;
    if (qActiveOrders.error || qInventories.error) return "Error";

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return <View orders={filteredOrders} filteredData={filteredData} activeTab={activeTab} handleTabClick={handleTabClick} />;
};

InventoryReportBuilding.propTypes = {
    search: PropTypes.string,
};

export default InventoryReportBuilding;
