import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import MaintenanceDetails from "components/maintenances/Details";
import MaintenanceList from "components/maintenances/List";
import MaintenanceFormSave from "components/maintenances/FormSave";
import MaintenanceFormSet from "components/maintenances/FormSet";
import MaintenanceReport from "components/maintenances/MaintenanceReport";
import { ModalRoute } from "seed/helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const MaintenancesView = ({
  cranes,
  searchRef,
  search,
  activeTab,
  handleChange,
  handleTabChange,
}) => (
  <BrowserRouter basename="/maintenances">
    <div className="content container-fluid p-7">
      {/* Header */}
      <div className="page-header pt-4">
        <div className="row align-items-end">
          <div className="col-sm">
            <h1 className="page-header-title">Mantenimientos</h1>
          </div>
          <div className="col-sm-auto">
            <div className="btn-group" role="group">
              <Link to="/create" className="btn btn-primary">
                <i className="tio-add mr-1"></i>Crear Mantenimiento
              </Link>
            </div>
            <div className="btn-group dropdown ml-1">
              <button
                className="dropdown-toggle-no-caret"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{ border: "none", backgroundColor: "white" }}
              >
                <i className="fas fa-ellipsis-v fa-lg"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="https://grokemx-my.sharepoint.com/personal/admin_grokemx_onmicrosoft_com/_layouts/15/onedrive.aspx?ga=1&id=%2Fpersonal%2Fadmin%5Fgrokemx%5Fonmicrosoft%5Fcom%2FDocuments%2Fgroke&view=0"
                >
                  <i className="fas fa-cloud" /> OneDrive
                </a>
                <a
                  className="dropdown-item"
                  href="https://docs.google.com/spreadsheets/d/18LoIPFrxsm-zTbcIk8Y1tDfUmDZ5rg9PusIvXTqlFqE/edit?usp=sharing"
                >
                  <i className="fas fa-bug" /> Reporte de incidencias técnicas
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
        <div className="scroll-horizontal" style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
          <ul 
            className="nav nav-tabs mb-3" 
            style={{ 
              display: 'inline-flex',
              padding: '0',       
              listStyle: 'none',
            }}
          >
            <li className="nav-item" style={{ display: 'inline-block' }}>
              <div
                onClick={() => handleTabChange("all")}
                style={{
                  cursor: 'pointer',
                  minWidth: '95px', 
                  backgroundColor: activeTab === "all" ? "#007bff" : "#f8f9fa",
                  color: activeTab === "all" ? "#fff" : "#000",
                  padding: '10px',
                  fontWeight: activeTab === "all" ? "bold" : "normal",
                  border: "1px solid #dee2e6",
                  borderRadius: "5px",
                  margin: '0 5px',
                  boxShadow: activeTab === "all" ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                  textAlign: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                Todos
              </div>
            </li>
            <li className="nav-item" style={{ display: 'inline-block' }}>
              <div
                onClick={() => handleTabChange("active")}
                style={{
                  cursor: 'pointer',
                  minWidth: '95px', 
                  backgroundColor: activeTab === "active" ? "#007bff" : "#f8f9fa",
                  color: activeTab === "active" ? "#fff" : "#000",
                  padding: '10px',
                  fontWeight: activeTab === "active" ? "bold" : "normal",
                  border: "1px solid #dee2e6",
                  borderRadius: "5px",
                  margin: '0 5px',
                  boxShadow: activeTab === "active" ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                  textAlign: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                Mantenimientos Activos
              </div>
            </li>
            <li className="nav-item" style={{ display: 'inline-block' }}>
              <div
                onClick={() => handleTabChange("custom")}
                style={{
                  cursor: 'pointer',
                  minWidth: '95px', 
                  backgroundColor: activeTab === "custom" ? "#007bff" : "#f8f9fa",
                  color: activeTab === "custom" ? "#fff" : "#000",
                  padding: '10px',
                  fontWeight: activeTab === "custom" ? "bold" : "normal",
                  border: "1px solid #dee2e6",
                  borderRadius: "5px",
                  margin: '0 5px',
                  boxShadow: activeTab === "custom" ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                  textAlign: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                Personalizado
              </div>
            </li>
          </ul>
        </div>

        <div style={{ minWidth: '250px', marginLeft: '20px' }}>
          <Typeahead
            id="menu"
            ref={searchRef}
            placeholder="Buscar Grúas"
            onInputChange={handleChange}
            labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
            onChange={(selected) =>
              selected.length > 0 ? handleChange(selected[0].series) : ""
            }
            options={cranes}
          >
            <div className="rbt-aux">
              {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
              {search.length > 0 && (
                <i
                  className="fa fa-times rbt-close text-danger"
                  style={{ paddingTop: "4px" }}
                  role="button"
                  onClick={() => {
                    searchRef.current.clear();
                    handleChange("");
                  }}
                ></i>
              )}
            </div>
          </Typeahead>
        </div>
      </div>

      <div className="row border font-weight-bold mx-1">
        <div className="col-md-1 p-2"></div>
        <div className="col-md-2 p-2">Grúa</div>
        <div className="col-md-1 p-2 text-center">Tipo</div>
        <div className="col-md-1 p-2 text-center">% Avance</div>
        <div className="col-md-1 p-2 text-center">Fecha de inicio</div>
        <div className="col-md-1 p-2 text-center">Fecha fin</div>
        <div className="col-md-1 p-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status</div>
        <div className="col-md-4 p-2 text-center">Acciones</div>
      </div>

      {/* List */}
      <MaintenanceList
        search={search}
        activeTab={activeTab}
      />

      {/* Modals */}
      <ModalRoute path="/create" component={MaintenanceFormSave} />
      <ModalRoute path="/:maintenanceId(\d+)/edit" component={MaintenanceFormSet} />
      <ModalRoute path="/:maintenanceId(\d+)/print" width={1000} height={700} component={MaintenanceReport} />
    </div>
  </BrowserRouter>
);

MaintenancesView.propTypes = {
  cranes: PropTypes.array,
  searchRef: PropTypes.object,
  search: PropTypes.string,
  activeTab: PropTypes.string,
  handleChange: PropTypes.func,
  handleTabChange: PropTypes.func,
  showOnlyActiveMaintenances: PropTypes.bool,
  setShowOnlyActiveMaintenances: PropTypes.func,
};

export default MaintenancesView;
