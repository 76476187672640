import React from "react";
import PropTypes from "prop-types";

const InventoryExceptionView = ({
    filteredExceptions,
    categories,
    showActive,
    setShowActive,
    activeTab,
    handleTabClick,
    onDelete,
    onUpdate
}) => {
    const truncateText = (text, maxLength) => {
        if (!text) return 'N/A';
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    };

    return (
        <div className="container mt-4 d-flex">
            {/* Tabs en la parte lateral */}
            <ul 
                className="nav flex-column nav-pills mr-3" 
                style={{ 
                    width: '200px', 
                    maxHeight: '60vh', 
                    overflowY: 'auto',  
                    overflowX: 'hidden', 
                    display: 'block',   
                    padding: '0',       
                    listStyle: 'none',  
                }}
            >
                {/* Lista de tabs con mapeo sobre las categorías */}
                {categories.map((category, index) => (
                    <li className="nav-item" key={index}>
                        <div
                            onClick={() => handleTabClick(category)}
                            style={{
                                cursor: 'pointer',
                                width: '180px',
                                backgroundColor: activeTab === category ? "#007bff" : "#f8f9fa",
                                color: activeTab === category ? "#fff" : "#000",
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: activeTab === category ? "bold" : "normal",
                                marginBottom: '5px',
                                border: "1px solid #dee2e6",
                                borderRadius: "5px",
                                boxShadow: activeTab === category ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                                whiteSpace: 'normal'  
                            }}
                        >
                            {category}
                        </div>
                    </li>
                ))}
            </ul>

            <div style={{ flexGrow: 1 }}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3></h3>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="showActive"
                            checked={showActive}
                            onChange={(e) => setShowActive(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="showActive">
                            Mostrar Activos
                        </label>
                    </div>
                </div>

                <div className="table-responsive" style={{ width: '100%' }}>
                    <div className="row border font-weight-bold mx-1">
                        <div className="col-md-2 p-2">ID de pieza</div>
                        <div className="col-md-3 p-2">Nombre de pieza</div>
                        <div className="col-md-2 p-2">Cantidad</div>
                        <div className="col-md-2 p-2">Estado</div>
                        <div className="col-md-2 p-2">Motivo</div>
                        <div className="col-md-1 p-2"></div>
                    </div>

                    {filteredExceptions.length === 0 ? (
                        <div className="col-md-12 text-center h5 mt-2">No hay resultados</div>
                    ) : (
                        <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                            {filteredExceptions.map((item, idx) => (
                                <div
                                    className="row border mx-1 text-reset"
                                    key={idx}
                                    style={{
                                        background: idx % 2 === 0 ? "#fff" : "#f5f5f5",
                                    }}
                                >
                                    <div className="col-md-2 p-2">{item.part.partId}</div>
                                    <div className="col-md-3 p-2">{item.part.name}</div>
                                    <div className="col-md-2 p-2">{item.quantity}</div>
                                    <div className="col-md-2 p-2">{item.status === "ACTIVE" ? "Activo" : "Archivado"}</div>
                                    <div className="col-md-2 p-2" title={item.reason}>
                                        {truncateText(item.reason, 30)} {/* Truncar a 30 caracteres */}
                                    </div>
                                    <div className="col-md-1 p-2">
                                        {item.status === "ACTIVE" && 
                                            <i onClick={() => onUpdate(item.id)} style = {{cursor: 'pointer'}}
                                                className="fa fa-archive text-warning"></i>
                                        }
                                        <i onClick={() => onDelete(item.id)} style = {{cursor: 'pointer'}}
                                            className="fa fa-trash text-danger ml-2"></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

InventoryExceptionView.propTypes = {
    filteredExceptions: PropTypes.array.isRequired,
    showActive: PropTypes.bool.isRequired,
    setShowActive: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,  
    onDelete: PropTypes.func.isRequired,     
    onUpdate: PropTypes.func.isRequired,     
};

export default InventoryExceptionView;
