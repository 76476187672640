import React from "react";
import PropTypes from "prop-types";

const PieceDetails = ({ details }) => (
    <div className="card shadow-sm p-4 mb-5">
        <h2 className="card-title text-center mb-4">Detalles de la Pieza</h2>

        {/* Información general de la pieza */}
        <div className="mb-4">
            <h5 className="card-subtitle mb-3 text-muted text-center">Información General</h5>
            <div className="row mb-3">
                <div className="col-md-6 text-center border-right">
                    <strong>Nombre de la pieza:</strong>
                    <p className="mt-2">{details?.name || 'N/A'}</p>
                </div>
                <div className="col-md-6 text-center">
                    <strong>ID de la pieza:</strong>
                    <p className="mt-2">{details?.part_id || 'N/A'}</p>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-md-6 border-right">
                    <strong>Total de piezas:</strong>
                    <span className="badge badge-info ml-2">{details?.total_pieces || 0}</span>
                </div>
                <div className="col-md-6">
                    <strong>Piezas en uso:</strong>
                    <span className="badge badge-warning ml-2">{details?.in_use || 0}</span>
                </div>
            </div>
        </div>

        <hr className="my-4" />

        <div className="mb-4">
            <h5 className="card-subtitle mb-3 text-muted text-center">Historial de Movimientos</h5>
            {!details.orders || details.orders.length === 0 ? (
                <div className="text-center text-muted">No hay movimientos asociados a esta pieza.</div>
            ) : (
                <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <table className="table table-hover table-bordered">
                        <thead style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                            <tr>
                                <th style={{ padding: '10px' }}>Fecha</th>
                                <th style={{ padding: '10px' }}>Cantidad</th>
                                <th style={{ padding: '10px' }}>Obra</th>
                                <th style={{ padding: '10px' }}>Cliente</th>
                                <th style={{ padding: '10px' }}>Grúa</th>
                                <th style={{ padding: '10px' }}>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.orders.map((order, index) => {
                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                                    <td style={{ padding: '12px' }}>
                                        {new Date(order.date).toLocaleDateString() || 'N/A'}
                                    </td>
                                    <td style={{ padding: '12px', display: 'flex', alignItems: 'center' }}>
                                        <span style={{ color: order.movement_type === "entrance" ? 'green' : 'red', marginRight: '8px' }}>
                                            {order.movement_type === "entrance" ? '↑' : '↓'}
                                        </span>
                                        {order.quantity}
                                    </td>
                                    <td style={{ padding: '12px' }}>
                                        {order.order_name || 'N/A'}
                                    </td>
                                    <td style={{ padding: '12px' }}>
                                        {order.order_client || 'N/A'}
                                    </td>
                                    <td style={{ padding: '12px' }}>
                                        {order.order_crane || 'N/A'}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    </div>
);

PieceDetails.propTypes = {
    piece: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
};

export default PieceDetails;
