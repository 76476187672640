import React from "react";
import PropTypes from "prop-types";
import View from "components/security_revisions/Form.view";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { useDetail, useQuery } from "seed/gql";
import swal from "sweetalert";

const SecurityRevisionFormSet = ({ securityId }) => {

    let initialRevision = 0;
    let finalRevision = 0;

    if (securityId.includes("-")) {
        [initialRevision, finalRevision] = securityId.split("-");
    } else {
        initialRevision = securityId;
        finalRevision = securityId;
    }

    const [callUpdate, setCallUpdate] = usePost(`/security_revisions/update_revision`, {
        onCompleted: (data) => {
            swal("¡Éxito!", "Revisión de seguridad actualizada.", "success").then(() => {
                window.location.href = `/security_revisions`;
            });
        },
        onError: (error) => {
            swal("¡Error!", "Ha ocurrido un error.", "error");
        }
    });
    
    const reqOrders = useQuery(`
        {
            orders{
                buildingName
                crane{
                    series
                    model
                    number
                }
            }
        }
    `);

    const getSecurityRevision = useDetail(`{
        securityRevision{
            order{
                buildingName
                crane{
                    series
                    model
                    number
                }
            }
        }
    }`, finalRevision);

    if (reqOrders.loading) return <Loading />;
    if (reqOrders.error) return <p>Error</p>;
    
    if (getSecurityRevision.loading) return <Loading />;
    if (getSecurityRevision.error) return <p>Error</p>;

    const orders = reqOrders.data.orders;
    const securityRevision = getSecurityRevision.data.securityRevision;

    const filteredOrders = orders.filter(order => order.crane !== null);

    const onSubmit = (values) => {

        delete values.id;
        values.securityId = securityId;

        if (!values.order) {
            swal("¡Error!", "Debe seleccionar una órden.", "error");
        }

        callUpdate(values);
    }

    return <View securityRevision={securityRevision} orders={filteredOrders} onSubmit={onSubmit} />;
}

SecurityRevisionFormSet.propTypes = {
    securityRevisionId: PropTypes.number.isRequired
}

export default SecurityRevisionFormSet;