import React, { useState } from "react";
import PropTypes from "prop-types";
import ViewGeneral from "components/inventory/admin/InventoryReport.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

const InventoryReport = () => {
    const path = window.location.pathname;
    const [activeTab, setActiveTab] = useState("TODOS");
    const [activeModel, setActiveModel] = useState(null); 

    const categories = ["TODOS", "TORRE", "GIRATORIA", "CUSPIDES", "PLUMA", "MECANISMOS", "CONTRAPLUMA", "MARCOS", "PIES", "PLATAFORMA", "CABLES", "JAULAS"];
    const modelGroups = {
        "5LC+LC5211": ["5LC5010", "5LC3510", "5LC4510", "LC5211"],
        "10LC+11LC+LC1044": ["10LC140", "10LC110", "10LC1044", "11LC132", "11LC160", "LC1044"],
        "21LC+LC2070+LC2074": ["21LC170", "21LC290", "21LC335", "21LC400", "21LC450", "21LC550", "21LC660", "21LC750", "21LC1050", "LC2070", "LC2074"],
        "LCL+D23": ["LCL"],
        "CM": [], 
        "ELEVADORES": [] 
    };

    const qInventories = useQuery(`{
        inventories{
            totalParts
            inUse
            category
            part{
                name
                partId
                isManual
                craneModel
                inventoryExceptions{
                    status
                }
            }
        }
    }`);

    if (qInventories.loading) return <Loading />;
    if (qInventories.error) return "Error";

    const inventories = qInventories.data.inventories;

    if (path === "/inventory") {
        const handleTabClick = (category) => {
            setActiveTab(category);
            if (category === "TODOS") {
                setActiveModel(null);
            }
        };

        const handleModelClick = (modelGroup) => {
            setActiveModel(modelGroup);
        };

        const filterDataByCategoryAndModel = (category, modelGroup) => {
            const modelList = modelGroups[modelGroup] || [];

            if (category === "TODOS" && !modelGroup) {
                return inventories;
            } else if (category === "TODOS" && modelGroup) {
                return inventories.filter(item => modelList.includes(item.part.craneModel));
            } else if (modelGroup) {
                return inventories.filter(item => item.category === category && modelList.includes(item.part.craneModel));
            } else {
                return inventories.filter(item => item.category === category);
            }
        };

        const filteredData = filterDataByCategoryAndModel(activeTab, activeModel);

        return (
            <ViewGeneral 
                filteredData={filteredData}
                handleTabClick={handleTabClick}
                handleModelClick={handleModelClick}
                activeTab={activeTab}
                activeModel={activeModel} 
                categories={categories} 
                models={Object.keys(modelGroups)} 
            />
        );
    }
};

InventoryReport.propTypes = {};

export default InventoryReport;
