import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import SecurityRevisionDetails from "components/security_revisions/Details";
import SecurityRevisionList from "components/security_revisions/List";
import SecurityRevisionFormSave from "components/security_revisions/FormSave";
import SecurityRevisionFormEdit from "components/security_revisions/FormSet";
import { ModalRoute } from "seed/helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const SecurityRevisionsView = ({
  cranes,
  search,
  orderDate,
  searchRef,
  handleChange,
  setOrderDate,
}) =>
  <BrowserRouter basename="/security_revisions">
    <div class="content container-fluid p-7">

    {/* Header */}
    <div class="page-header pt-4">
      <div class="row align-items-end">

        <div class="col-sm">
          <h1 class="page-header-title">Revisiones de seguridad</h1>
        </div>

        <div class="col-sm-auto">
          <div class="btn-group" role="group">
            <Link to="/create_revision" className="btn btn-primary">
              <i class="tio-add mr-1"></i>Crear Revisión de Seguridad
            </Link>
          </div>
        </div>

      </div>
    </div>

    <div className="mb-3 mx-1">
        <div className="row">
          <div className="col-md-6">
            <h4>Revisiones registradas</h4>
          </div>
          <div className="col-md-6">
            <div className="float-right">
              <Typeahead
                id="menu"
                ref={searchRef}
                placeholder="Buscar grúa..."
                onInputChange={handleChange}
                labelKey={(crane) =>
                  `${crane.series} - ${crane.model} ${crane.number}`
                }
                onChange={(selected) =>
                  selected.length > 0 ? handleChange(selected[0].series) : ""
                }
                options={cranes}
              >
                <div className="rbt-aux">
                  {search.length == 0 && (
                    <i className="fa fa-search rbt-aux"></i>
                  )}
                  {search.length > 0 && (
                    <i
                      className="fa fa-times rbt-close text-danger"
                      style={{ paddingTop: "4px" }}
                      role="button"
                      onClick={() => {
                        searchRef.current.clear();
                        handleChange("");
                      }}
                    ></i>
                  )}
                </div>
              </Typeahead>
            </div>
          </div>
        </div>
      </div>

    <div className="row border font-weight-bold mx-1">
      <div className="col-md-2 p-2 text-center">
        Grúa
      </div>
      <div className="col-md-2 p-2 text-center">
        Orden
      </div>
      <div className="col-md-2 p-2">
        <div className="d-flex flex-row align-items-center ml-9" style={{ marginTop: "-10px", marginBottom: "-10px" }}>
          <span>Fecha</span>
          <div className="mx-1">
            <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "" ? "text-primary" : ""}`}
              onClick={() => setOrderDate("")}
            >
              <i className="fa fa-arrow-up"></i>
            </button>
            <button type="button" className={`btn btn-text mx-1 px-0 ${orderDate == "-" ? "text-primary" : ""}`}
              onClick={() => setOrderDate("-")}
            >
              <i className="fa fa-arrow-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-2 p-2 text-center">
        Electromecánica
      </div>
      <div className="col-md-2 p-2 text-center">
        Estructura y Componentes
      </div>
      <div className="col-md-2 p-2 text-center">
      </div>
    </div>

    {/* List */}
    <SecurityRevisionList 
      search={search}
      orderDate={orderDate}
    />

    {/* Modals */}
    <ModalRoute
      path="/create_revision"
      width={1000}
      height={600}
      component={SecurityRevisionFormSave} />

    <ModalRoute 
      path="/:securityRevisionId([a-zA-Z0-9-]+)/details"
      width={1000}
      height={750}
      component={SecurityRevisionDetails} />

    <ModalRoute
      path="/:securityId([a-zA-Z0-9-]+)/edit"
      width={1000}
      height={600}
      component={SecurityRevisionFormEdit} />

    </div>
  </BrowserRouter>;

SecurityRevisionsView.propTypes = {
  cranes: PropTypes.array,
  search: PropTypes.string,
  orderDate: PropTypes.string,
  searchRef: PropTypes.object,
  handleChange: PropTypes.func,
  setOrderDate: PropTypes.func,
};

export default SecurityRevisionsView;