import React, { useState } from "react";
import { useQuery, usePagination, useDelete, useSet } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePut } from "seed/api";
import { DELETE_MAINTENANCE, SET_MAINTENANCE } from "seed/gql/queries";
import View from "components/maintenances/List.view";
import { formatFilters, formatSearchFilters } from "components/utils/filters";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useHistory } from "react-router-dom";

const MaintenanceList = ({ search, activeTab }) => {

  const pageSize = 10;
  const [pageNum, setPageNum] = useState(1);

  const [updateVisible, setUpdateVisible] = useSet(SET_MAINTENANCE, {
    onCompleted: () => {    },
    onError: (error) => {
        console.log(error);
    },
  });

  const reqActiveMaintenances = usePagination(`
    {
      maintenancePagination {
        totalPages
        maintenances {
          status
          type
          createdAt
          isVisible
          maintenanceEvidences{
            maintenanceFiles{
              file{
                url
              }
            }
            skipEvidence
            type{
              name
              inputType
              category{
                name
              }
            }
          }
          start
          end
          crane {
            model
            craneId
            series
            number
          }
        }
      }
    }`,
    pageNum,
    pageSize,
    formatFilters([
      "status=ACTIVE",
      formatSearchFilters(search, ["crane.series", "crane.model"])
    ]),
    {orderBy: "-start"}
  );

  const reqMaintenances = usePagination(`
    {
      maintenancePagination {
        totalPages
        maintenances {
          status
          type
          createdAt
          isVisible
          maintenanceEvidences{
            maintenanceFiles{
              file{
                url
              }
            }
            skipEvidence
            type{
              name
              inputType
              category{
                name
              }
            }
          }
          start
          end
          crane {
            model
            craneId
            series
            number
          }
        }
      }
    }`,
    pageNum,
    pageSize,
    formatFilters([
      formatSearchFilters(search, ["crane.series", "crane.model"])
    ]),
    {orderBy: "-id"}
  );

  const calculateEvidencePercentage = (maintenance) => {
    let typesCount = 0;
    let elementsWithEvidence = 0;
  
    maintenance.maintenanceEvidences.forEach(evidence => {
      if (evidence.type.inputType === "FILE" && evidence.skipEvidence === false) {
        typesCount++;
        if (evidence.maintenanceFiles.length > 0 && !evidence.skipEvidence) {
          elementsWithEvidence++;
        }
      }
    });
  
    return typesCount > 0 ? (elementsWithEvidence * 100 / typesCount).toFixed(2) : 0;
  };

  const useGetActiveMaintenancesExist = (craneId) => {

    let qMaintenancesOfCrane = useQuery(`
    {
      maintenances {
        id
      }
    }`, "crane.id=" + craneId + " AND status=ACTIVE")

    let maintenances = qMaintenancesOfCrane.data.maintenances

    if (maintenances != undefined)
      return maintenances.length ?? maintenances.length > 0

  };

  const [callUploadEvidence, qUploadEvidence] = usePost("/maintenances/maintenance_onedrive", {
    onCompleted: () =>
      window.location.replace("/maintenances"),
    onError: () =>
      window.location.replace("/maintenances")
  });

  const [ callDelete, reqDelete ] = useDelete(DELETE_MAINTENANCE, {
    onCompleted: () => {}
  });

  const [callSet, qSet] = usePut("/maintenances", {
    onCompleted: (data) =>
      callUploadEvidence({ maintenance_id: data["id"] })
  });


  if (reqActiveMaintenances.loading || reqMaintenances.loading) return <Loading />;
  if (reqActiveMaintenances.error || reqMaintenances.error) return "Error";

  const { maintenances = [], totalPages = 0 } = activeTab === "active" || activeTab === "custom"
  ? reqActiveMaintenances.data.maintenancePagination
    : reqMaintenances.data.maintenancePagination;

  const filteredMaintenances = activeTab === "custom"
    ? maintenances.filter((maintenance) => maintenance.isVisible)
    : maintenances;

  const updateStatus = (maintenanceId, currentStatus) => {

    let newStatus;

    if (currentStatus == "ACTIVE")
      newStatus = "FINISHED"
    else if (currentStatus == "FINISHED")
      newStatus = "ACTIVE"

    let result = {
      id: maintenanceId,
      status: newStatus
    }

    callSet(result);

  }

  const redirectToCategories = (maintenanceId) =>
    window.location.href = "/maintenances/categories/" + maintenanceId;

  const redirectToResponsibles = (maintenanceId) =>
    window.location.href = "/maintenances/responsibles/" + maintenanceId;

  const deleteMaintenance = (maintenanceId) => {
    let confirm = window.confirm("¿Está seguro de eliminar el mantenimiento? Se eliminarán los, " 
      + "contenidos y evidencias que pertenezcan a este mantenimiento.");
    if(confirm)
      callDelete({ id: maintenanceId });
  } 

  const toggleSelectMaintenance = (maintenanceId, value) => {
    updateVisible({ id: maintenanceId, isVisible: !value });
  };

  const onClickPage = (pageNum) =>
    setPageNum(pageNum);

  return <View
    maintenances={filteredMaintenances}
    pageNum={pageNum}
    totalPages={totalPages}
    onClickPage={onClickPage}
    updateStatus={updateStatus}
    deleteMaintenance={deleteMaintenance}
    toggleSelectMaintenance={toggleSelectMaintenance}
    calculateEvidencePercentage={calculateEvidencePercentage}
    activeMaintenanceExist={useGetActiveMaintenancesExist}
    redirectToCategories={redirectToCategories}
    redirectToResponsibles={redirectToResponsibles}
  />;

}

MaintenanceList.propTypes = {
  search: PropTypes.string
};

export default MaintenanceList;