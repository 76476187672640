import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/inventory/admin/Form.view";
import { usePost } from "seed/api";
import swal from "sweetalert";

const Form = () => {

    const [step, setStep] = useState(1);

    const [callSave, setCallSave] = usePost("/inventories/create_parts", {
        onCompleted: () => {
            swal("¡Éxito!", "Pieza creada exitosamente.", "success").then(() => {
                window.location.href = "/inventory";
            });
        },
        onError: () => {
            swal("¡Error!", "Error al crear la pieza.", "error")
        }
    });

    const onSubmit = (values) => {
        callSave(values);
    };

    return <View step={step} onSubmit={onSubmit} />;
}

Form.propTypes = {};

export default Form;