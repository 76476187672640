import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDelete, useSet } from "seed/gql";
import { Loading } from "seed/helpers";
import { DELETE_INVENTORY_EXCEPTION, SET_INVENTORY, SET_INVENTORY_EXCEPTION } from "seed/gql/queries";
import View from "components/inventory/exceptions/InventoryException.view";
import swal from "sweetalert";

const InventoryException = () => {
    const [showActive, setShowActive] = useState(true);
    const [activeTab, setActiveTab] = useState("TODOS");
    const categories = ["TODOS", "TORRE", "GIRATORIA", "CUSPIDES", "PLUMA", "MECANISMOS", "CONTRAPLUMA", "MARCOS", "PIES", "PLATAFORMA", "CABLES", "JAULAS"];

    const qExceptions = useQuery(`{
        inventoryExceptions{
            reason
            quantity
            status
            part{
                name
                partId
                craneModel
                inventories{
                    category
                }
            }
        }
    }`);

    const [ callDelete, setCallDelete ] = useDelete(DELETE_INVENTORY_EXCEPTION, {
        onCompleted: () => {
            swal("¡Éxito!", "Excepción eliminada exitosamente.", "success").then(() => {
                qExceptions.refetch();
            });
        }
    });

    const [ callUpdate, setCallUpdate ] = useSet(SET_INVENTORY_EXCEPTION, {
        onCompleted: () => {
            swal("¡Éxito!", "Excepción actualizada exitosamente.", "success").then(() => {
                qExceptions.refetch();
            });
        }
    });

    if (qExceptions.loading) return <Loading />;
    if (qExceptions.error) return "Error";

    const dummyData = qExceptions.data.inventoryExceptions;


    const filterData = (category, statusFilter) => {
        let filteredData = dummyData;

        if (category !== "TODOS") {
            filteredData = filteredData.filter(item => item?.part?.inventories[0]?.category === category);
        }

        if (statusFilter) {
            filteredData = filteredData.filter(item => item.status === "ACTIVE" || item.status === "FILED");
        } else {
            filteredData = filteredData.filter(item => item.status === "FILED");
        }

        return filteredData;
    };

    const handleTabClick = (category) => {
        setActiveTab(category);
    };

    const onDelete = (id) => {
        swal("¿Está seguro de eliminar la excepción?", {
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                callDelete({ id: Number(id) });
            }
        });
    };

    const onUpdate = (id) => {
        swal("¿Está seguro de archivar la excepción?", {
            buttons: ["Cancelar", "Archivar"],
            dangerMode: true,
        }).then((willUpdate) => {
            if (willUpdate) {
                callUpdate({ id: Number(id), status: "FILED" });
            }
        });
    }

    const filteredExceptions = filterData(activeTab, showActive);

    return <View 
            filteredExceptions={filteredExceptions}
            categories={categories}
            showActive={showActive} 
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setShowActive={setShowActive}
            onDelete={onDelete}
            onUpdate={onUpdate} />;
}

InventoryException.propTypes = {
    filteredExceptions: PropTypes.array,
    showActive: PropTypes.bool,
    activeTab: PropTypes.string,
    handleTabClick: PropTypes.func
};

export default InventoryException;