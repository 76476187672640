import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/inventory/exceptions/Form.view";
import swal from "sweetalert";

const Form = () => {

    const [step, setStep] = useState(1);

    const [callSave, setCallSave] = usePost("/inventory_exceptions/create_exception", {
        onCompleted: () => {
            swal("¡Éxito!", "Excepción creada exitosamente.", "success").then(() => {
                window.location.href = "/inventory/exceptions";
            });
        },
        onError: () => {
            swal("¡Error!", "Error al crear la pieza.", "error")
        }
    });

    const qParts = useQuery(`{
        parts{
            name
            partId
            craneModel
        }
    }`,
    "is_manual = 1");

    if (qParts.loading) return <Loading />;

    const { parts } = qParts.data;

    const onSubmit = (values) => {
        values.reason = document.getElementById("reason").value;
        console.log(values);
        callSave(values);
    }

    return <View step={step} onSubmit={onSubmit} parts={parts} />;
}

Form.propTypes = {};

export default Form;