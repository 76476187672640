import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const InventoryReportView = ({
    filteredData,
    activeTab,
    handleTabClick,
    orders 
}) => (
    <div className="container mt-4 d-flex">
        {/* Tabs en la parte lateral con scroll vertical */}
        <ul 
            className="nav flex-column nav-pills mr-3" 
            style={{ 
                width: '235px', 
                maxHeight: '60vh',  
                overflowY: 'auto',
                overflowX: 'hidden', 
                display: 'block',   
                padding: '0',       
                listStyle: 'none',
            }}
        >
            {/* Mapeo de las órdenes con scroll */}
            {orders.map((order, index) => (
                <li className="nav-item" key={index}>
                    <div
                        onClick={() => handleTabClick(order.id)}
                        style={{
                            cursor: 'pointer',
                            width: '180px',
                            backgroundColor: activeTab === order.id ? "#007bff" : "#f8f9fa",
                            color: activeTab === order.id ? "#fff" : "#000",
                            textAlign: 'center',
                            padding: '10px',
                            fontWeight: activeTab === order.id ? "bold" : "normal",
                            marginBottom: '5px',
                            border: "1px solid #dee2e6",
                            borderRadius: "5px",
                            boxShadow: activeTab === order.id ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                        }}
                    >
                        {order.buildingName}
                    </div>
                </li>
            ))}
        </ul>

        {/* Tabla de datos filtrados */}
        <div className="table-responsive" style={{ width: '100%' }}>
            <div className="row border font-weight-bold mx-1">
                <div className="col-md-2 p-2">ID de pieza</div>
                <div className="col-md-3 p-2">Nombre de pieza</div>
                <div className="col-md-2 p-2">Totales</div>
                <div className="col-md-2 p-2">En taller</div>
                <div className="col-md-2 p-2">En obra</div>
                <div className="col-md-1 p-2"></div>
            </div>

            {filteredData.length === 0 ? (
                <div className="col-md-12 text-center h5 mt-2">No hay resultados</div>
            ) : (
                <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                    {filteredData.map((item, idx) => (
                        <div
                            className="row border mx-1 text-reset"
                            key={idx}
                            style={{
                                background: idx % 2 === 0 ? "#fff" : "#f5f5f5",
                            }}
                        >
                            <div className="col-md-2 p-2">
                                {item.part_id}
                                <div>
                                    {!item.part.isManual ? (
                                        <span className="badge" style={{ backgroundColor: "#d3d3d3" }}>Precargada</span>
                                    ) : (
                                        <span className="badge" style={{ backgroundColor: "#90ee90" }}>Manual</span>
                                    )}
                                </div>
                                {item.part.inventoryExceptions && item.part.inventoryExceptions.some(exception => exception.status === "ACTIVE") && (
                                    <div>
                                        <span className="badge" style={{ backgroundColor: "#f08080" }}>Con excepciones</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3 p-2">{item.part.name}</div>
                            <div className="col-md-2 p-2">{item.totalParts}</div>
                            <div className="col-md-2 p-2">{item.totalParts - item.inUse}</div>
                            <div className="col-md-2 p-2">{item.inUse}</div>
                            <div className="col-md-1 p-2">
                                <Link to={{ pathname: `/edit/${item.id}` }} className="text-warning ml-2">
                                    <i className="fa fa-edit"></i>
                                </Link>
                                <Link to={{ pathname: `/details/${item.id}` }} className="text-dark ml-2">
                                    <i className="fas fa-tools"></i>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    </div>
);

InventoryReportView.propTypes = {
    filteredData: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    orders: PropTypes.array.isRequired,  // Cambié de 'works' a 'orders'
};

export default InventoryReportView;
