import React from "react";
import { BrowserRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import InventoryReport from "components/inventory/admin/InventoryReport";
import InventoryReportBuilding from "components/inventory/admin/InventoryReportBuilding";
import InventoryException from "components/inventory/exceptions/InventoryException";
import CreatePart from "components/inventory/admin/FormSave";
import EditPart from "components/inventory/admin/FormSet";
import CreateException from "components/inventory/exceptions/FormSave";
import PieceDetails from "components/inventory/admin/Details";
import { Typeahead } from "react-bootstrap-typeahead";
import { uniqBy } from "lodash";

const InventoryView = ({
    orders,
    search,
    onClose,
    pathname,
    searchRef,
    handleChange,
}) =>
    <BrowserRouter basename={pathname}>
        <div class="content container-fluid p-7">
            <div class="page-header pt-4">
                <div class="row align-items-end">
                    <div class="col-sm">
                        <h1 class="page-header-title">Inventario</h1>
                    </div>
                    <div class="col-sm-auto">
                        {pathname === "/inventory" || pathname === "/inventory/building" ? 
                            <Link to="/create" className="btn btn-primary mx-3">
                                <i class="tio-add mr-2"></i>Cargar nueva pieza
                            </Link>
                            :
                            <Link to="/create_exception" className="btn btn-primary mx-3">
                                <i class="tio-add mr-2"></i>Nueva excepción
                            </Link>
                        }
                        <div class="btn-group dropdown ml-1">
                            <button class="dropdown-toggle-no-caret" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-expanded="false" style={{ border: "none", backgroundColor: "white" }}>
                                <i class="fas fa-ellipsis-v fa-lg"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"
                                    href="https://docs.google.com/spreadsheets/d/1QzuwoXVD8xavZyiIZzw1No-SOGKGBOy4-H_ATF31iI4/edit?usp=sharing">
                                    <i class="fas fa-bug" /> Reporte de incidencias técnicas
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3 mx-1">
                <div className="row">
                    <div className="col-md-10">
                        <h4>
                            {pathname === "/inventory" ? "Reporte de piezas" : pathname === "/inventory/building" ? "Reporte de piezas por obra" : "Excepciones de piezas"}
                        </h4>
                    </div>
                    <div className="col-md-2">
                        <div className="">
                            {pathname === "/inventory/building" ? (
                            <Typeahead
                                id="menu"
                                ref={searchRef}
                                placeholder={`Buscar por obra`}
                                onInputChange={handleChange}
                                labelKey={(order) => `${order.buildingName}`}
                                onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].buildingName) : "")}
                                options={uniqBy(orders, "buildingName")}
                            >
                                <div className="rbt-aux">
                                {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                                {search.length > 0 && (
                                    <i
                                    className="fa fa-times rbt-close text-danger"
                                    style={{ paddingTop: "4px" }}
                                    role="button"
                                    onClick={() => {
                                        searchRef.current.clear();
                                        handleChange("");
                                    }}
                                    ></i>
                                )}
                                </div>
                            </Typeahead>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            {pathname === "/inventory" ?
                <InventoryReport/>
                : pathname === "/inventory/building" ? <InventoryReportBuilding search={search}/>
                    : <InventoryException/>
            }

        </div>

        <ModalRoute
            path="/create"
            width="750"
            height="420"
            component={CreatePart}
            onClose={onClose}
        />

        <ModalRoute
            path="/create_exception"
            width="750"
            height="420"
            component={CreateException}
            onClose={onClose}
        />

        <ModalRoute
            path="/details/:inventoryId"
            width="750"
            height="600"
            component={PieceDetails}
            onClose={onClose}
        />

        <ModalRoute
            path="/edit/:inventoryId"
            width="750"
            height="330"
            component={EditPart}
            onClose={onClose}
        />

    </BrowserRouter>;

InventoryView.propTypes = {
    pathname: PropTypes.string
};

export default InventoryView;
