import React, {useState} from "react";
import PropTypes from "prop-types";
import View from "components/inventory/admin/Details.view";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";

const Details = ({ inventoryId }) => {

    const [details, setDetails] = useState({});

    const pieceDetails = useGet("/inventories/part_details", {"inventory_id": inventoryId}, {
        onCompleted: (data) => {
            setDetails(data);
        },
        onError: (error) => {
            console.error(error);
        }
    });

    if (pieceDetails.loading) return <Loading />;
    if (pieceDetails.error) return "Error";

    console.log(details);

    return <View details={details} />;
}

Details.propTypes = {
    pieceId: PropTypes.string,
};

export default Details;