import React, {useState,useRef} from "react";
import View from "components/inventory/Inventory.view";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

const Inventory = () => {

    const searchRef = useRef(null);
    const pathname = window.location.pathname;
    const [created, setCreated] = React.useState(false);
    const history = useHistory();
    const [search, setSearch] = useState("");

    const qOrders = useQuery(`{
        orders {
          buildingName
          clientName
          status
          applicant {
            id
            firstName
            lastName
          }
        }
    }`,"status=CHARGING OR status=IN_BUILDING OR status=TRAVELING");
    
    if(qOrders.loading) return <Loading />;
    if(qOrders.error) return "Error";

    const orders = qOrders.data.orders;
    
    const handleChange = (value) => 
        setSearch(value);
    
    const onClose = () => {    
        setCreated(false);
        history.goBack();
    }

    return <View
        orders={orders}
        search={search}
        onClose={onClose}
        pathname={pathname}
        searchRef={searchRef}
        handleChange={handleChange}
    />;
}

Inventory.propTypes = {};

export default Inventory;