import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const FormView = ({ inventory = {}, onSubmit, onClose, error, step }) =>
    <div className="card h-100">

        {/* Header */}
        {step === 1 && (
            <>
                <div className="card-header">
                    <h3 className="card-header-title">
                        {inventory.id ? "Editar pieza" : "Nueva pieza"}
                    </h3>
                </div>
            </>
        )}

        {/* Body */}
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <Formik
                        initialValues={{
                            name: inventory?.part?.name || "",
                            part_id: inventory?.part?.partId || "",
                            total_pieces: inventory?.totalParts || "",
                            category: inventory?.category || "",
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {step === 1 && (
                                    <>
                                        <div className="mb-3">
                                            <div className="row">
                                                {/* Nombre de la pieza */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Nombre de la pieza</label>
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.name}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {/* ID de la pieza */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">ID de la pieza</label>
                                                        <Field
                                                            type="text"
                                                            name="part_id"
                                                            id="part_id"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.part_id}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* Número total de piezas */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Número total de piezas</label>
                                                        <Field
                                                            type="number"
                                                            name="total_pieces"
                                                            id="total_pieces"
                                                            className="form-control"
                                                            required
                                                            defaultValue={values.total_pieces}
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {/* Categoría */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Categoría</label>
                                                        <Field
                                                            component="select"
                                                            name="category"
                                                            id="category"
                                                            className="form-control"
                                                            value={values.category}
                                                        >
                                                            <option value="">Seleccione una categoría</option>
                                                            <option value="TORRE">Torre</option>
                                                            <option value="GIRATORIA">Giratoria</option>
                                                            <option value="CUSPIDES">Cúspides</option>
                                                            <option value="PLUMA">Pluma</option>
                                                            <option value="MECANISMOS">Mecanismos</option>
                                                            <option value="CONTRAPLUMA">Contrapluma</option>
                                                            <option value="MARCOS">Marcos</option>
                                                            <option value="PIES">Pies</option>
                                                            <option value="PLATAFORMA">Plataforma</option>
                                                            <option value="CABLES">Cables</option>
                                                            <option value="JAULAS">Jaulas</option>
                                                        </Field>
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>

                                                {!inventory.id && ( 
                                                <div className="col-md-12">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Modelo de Grúa</label>
                                                        <Field
                                                            component="select"
                                                            name="crane_model"
                                                            id="crane_model"
                                                            className="form-control"
                                                            value={values.crane_model}
                                                        >
                                                            <option value="">Seleccione un modelo de grúa</option>
                                                            <option value="5LC5010">5LC5010</option>
                                                            <option value="5LC3510">5LC3510</option>
                                                            <option value="5LC4510">5LC4510</option>
                                                            <option value="10LC140">10LC140</option>
                                                            <option value="10LC110">10LC110</option>
                                                            <option value="10LC1044">10LC1044</option>
                                                            <option value="11LC132">11LC132</option>
                                                            <option value="11LC160">11LC160</option>
                                                            <option value="21LC170">21LC170</option>
                                                            <option value="21LC290">21LC290</option>
                                                            <option value="21LC335">21LC335</option>
                                                            <option value="21LC400">21LC400</option>
                                                            <option value="21LC450">21LC450</option>
                                                            <option value="21L550">21LC550</option>
                                                            <option value="21LC660">21LC660</option>
                                                            <option value="21LC750">21LC750</option>
                                                            <option value="21LC1050">21LC1050</option>
                                                            <option value="LCL">LCL</option>
                                                            <option value="LC5211">LC5211</option>
                                                            <option value="LC2070">LC2070</option>
                                                        </Field>
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Botones de acción */}
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-block btn-primary">
                                                    {inventory.id ? "Actualizar pieza" : "Crear pieza"}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <div className="alert alert-success" role="alert">
                                            {error === null
                                                ? inventory.id
                                                    ? "Pieza actualizada correctamente"
                                                    : "Pieza creada correctamente"
                                                : error}
                                        </div>
                                        <button className="btn btn-block btn-primary" onClick={onClose}>
                                            Listo
                                        </button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
    ;

FormView.propTypes = {
    inventory: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    error: PropTypes.string,
    step: PropTypes.number,
};

export default FormView;