import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CheckEvidences from "components/binnacle/CheckEvidences";

const OperationDetails = ({ shipping, groupEvidences, groupRevisions, selectedEvidences, setSelectedEvidences }) => {
  const hasEntryDeviations = shipping.contents.some(content =>
    content.operations.some(operation =>
      operation.isEntry &&
      operation.contentDesviation !== "" &&
      operation.itemChecked.split(";").some(item => item === "false")
    )
  );

  const hasReviewDeviations = shipping.contents.some(content =>
    content.operations.some(operation =>
      operation.isSupervision &&
      operation.contentDesviation !== "" &&
      operation.itemChecked.split(";").some(item => item === "false")
    )
  );

  return (
    <div className="card">
      {selectedEvidences != null && (
        <CheckEvidences
          evidences={selectedEvidences}
          setSelectedEvidences={setSelectedEvidences}
        />
      )}

      <div className="card-header">
        <div className="d-flex flex-column">
          <h3 className="card-title valid">Datos del Envío</h3>
        </div>
      </div>

      <div className="card-body">
        <h2>Datos de la Obra</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Obra</label>
              <input type="text" className="form-control" value={shipping.order.buildingName} disabled />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Cliente</label>
              <input type="text" className="form-control" value={shipping.order.clientName} disabled />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Dirección</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={`${shipping.order.buildingAddress}`}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Transportista</label>
              <input type="text" className="form-control" value={shipping.transportCarrierName} disabled />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Placas</label>
              <input type="text" className="form-control" value={shipping.transportPlate} disabled />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-group-default">
              <label className="control-label">Fecha de entrega</label>
              <input type="text" className="form-control" value={moment(shipping.deliveryDate).format("DD/MM/YYYY")} disabled />
            </div>
          </div>
        </div>

        <h2 className="mt-4">Lista de embarque</h2>
        <div className="row">
          {shipping.contents.length === 0 ? (
            <div className="col-md-12 text-center">
              <p>No hay partes revisadas registradas</p>
            </div>
          ) : (
            <div className="col-md-12">
              <table className="table table-bordered table-hover table-striped" style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <tbody>
                  {shipping.contents.reduce((rows, content, index) => {
                    if (index % 2 === 0) rows.push([]);
                    rows[rows.length - 1].push(content);
                    return rows;
                  }, []).map((row, rowIndex) => (
                    <tr key={rowIndex} className="text-center" style={{ border: "2px solid #dee2e6" }}>
                      {row.map((content) => (
                        <td key={content.partId} className="p-3" style={{ border: "2px solid #dee2e6" }}>
                          <div className="d-flex flex-column align-items-center">
                            <strong style={{ fontSize: "1.1em" }}>{content.partName}</strong>
                            <span className="text-muted" style={{ fontSize: "0.9em" }}>
                              {content.partId}
                            </span>
                          </div>
                        </td>
                      ))}
                      {row.length < 2 && <td className="p-3" style={{ border: "2px solid #dee2e6" }}></td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <h2 className="mt-4">Evidencias</h2>
        <div className="row">
          {shipping.evidences.length === 0 ? (
            <div className="d-flex text-center w-100 align-items-center justify-content-center" style={{ minHeight: "5em" }}>
              <h4>No hay evidencias registradas</h4>
            </div>
          ) : (
            <div style={{ overflowY: "auto" }} className="col-md-12">
              {(() => {
                const grouped = groupEvidences();
                const userTypes = Object.keys(grouped);

                return userTypes.map((key, idx1) => {
                  const users = Object.keys(grouped[key]);

                  return users.map((key2, idx2) => (
                    <div key={`${idx1}-${idx2}`}>
                      <div className="d-flex">
                        <h4>
                          {grouped[key][key2][0].user.firstName} {grouped[key][key2][0].user.lastName}
                        </h4>
                        <h5 className="mx-2">-</h5>
                        <b>
                          {(() => {
                            switch (grouped[key][key2][0].user.rol) {
                              case "ADMIN":
                                return "Administrador";
                              case "BUILDING_OPERATOR":
                                return "Operador de obra";
                              case "WORKSHOP_OPERATOR":
                                return "Trabajador taller";
                              case "QUALITY_CONTROL":
                                return "Control de calidad";
                              case "RECOLECTOR":
                                return "Recolector";
                              case "SHIPPING_REVIEWER":
                                return "Revisor de envíos";
                              default:
                                return "";
                            }
                          })()}
                        </b>
                      </div>
                      <div className="d-flex align-items-center w-100 mb-2" style={{ overflowX: "auto" }}>
                        {grouped[key][key2].map((evidence, idx3) => (
                          <div key={`${idx1}-${idx2}-${idx3}`} className="d-flex flex-column align-items-center mr-3">
                            <a href={evidence.evidenceFile.url} target="_blank" rel="noopener noreferrer">
                              {evidence.evidenceFile.url.includes("mp4") ? (
                                <video style={{ maxHeight: "12em" }} className="img-fluid mx-0" controls muted>
                                  <source src={evidence.evidenceFile.url} type="video/mp4" />
                                </video>
                              ) : (
                                <img
                                  style={{ maxHeight: "12em" }}
                                  className="img-fluid mx-0"
                                  src={evidence.evidenceFile.url}
                                  alt={`Evidencia ${idx3}`}
                                />
                              )}
                            </a>
                            <b className="mt-1">{moment.utc(evidence.createdAt).local().format("DD/MM/YYYY HH:mm")}</b>
                          </div>
                        ))}
                      </div>
                      {idx1 !== userTypes.length - 1 && idx2 !== users.length - 1 && <hr style={{ width: "100%" }}></hr>}
                    </div>
                  ));
                });
              })()}
            </div>
          )}
        </div>

        <h2 className="mt-4">Desviaciones durante la entrada</h2>
        <div className="row">
          {hasEntryDeviations ? (
            shipping.contents.map((content) => {
              const matchingEntryOperations = content.operations.filter((operation) => {
                return (
                  operation.isEntry &&
                  operation.contentDesviation !== "" &&
                  operation.itemChecked.split(";").some((item) => item === "false")
                );
              });

              return matchingEntryOperations.length > 0 ? (
                <div key={content.id} className="col-md-12">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Parte con desviaciones: {content.partName}</h5>
                      {matchingEntryOperations.map((operation) => (
                        <div key={operation.id} className="alert alert-warning" role="alert">
                          <strong>Desviación:</strong> {operation.contentDesviation}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null;
            })
          ) : (
            <div className="col-md-12">
              <div className="alert alert-info" role="alert">
                No se encontraron desviaciones durante la entrada.
              </div>
            </div>
          )}
        </div>

        <h2 className="mt-4">Desviaciones durante la revisión</h2>
        <div className="row">
          {hasReviewDeviations ? (
            shipping.contents.map((content) => {
              const matchingReviewOperations = content.operations.filter((operation) => {
                return (
                  operation.isSupervision &&
                  operation.contentDesviation !== "" &&
                  operation.itemChecked.split(";").some((item) => item === "false")
                );
              });

              return matchingReviewOperations.length > 0 ? (
                <div key={content.id} className="col-md-12">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Parte con desviaciones: {content.partName}</h5>
                      {matchingReviewOperations.map((operation) => (
                        <div key={operation.id} className="alert alert-danger" role="alert">
                          <strong>Desviación:</strong> {operation.contentDesviation}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null;
            })
          ) : (
            <div className="col-md-12">
              <div className="alert alert-info" role="alert">
                No se encontraron desviaciones durante la revisión.
              </div>
            </div>
          )}
        </div>


        <h2 className="mt-4">Revisiones</h2>
        <div className="row">
          {shipping.order.securityRevisions.length === 0 ? (
            <div className="d-flex text-center w-100 align-items-center justify-content-center" style={{ minHeight: "5em" }}>
              <h4>No hay revisiones registradas</h4>
            </div>
          ) : (
            <div style={{ overflowY: "auto" }} className="col-md-12">
              {(() => {
                const grouped = groupRevisions();
                return grouped.map((revision, idx) => (
                  <div key={idx} className="card">
                    <div className="card-body">
                      <div className="row">
                        {revision.map((check, idx2) => (
                          <div key={idx2} className="col-md-12">
                            <table className="table table-bordered table-sm border text-center">
                              <thead className="bg-primary text-white">
                                <tr>
                                  <th colSpan={5} className="d-flex justify-content-center align-items-center">
                                    {check.name} {check.partId}
                                    <button
                                      className="btn btn-link text-center accordion-btn text-white"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#id${idx2}`}
                                      aria-expanded="true"
                                      aria-controls={`id${idx2}`}
                                    >
                                      <i className="fa fa-chevron-down"></i>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="collapse" id={`id${idx2}`}>
                                <tr>
                                  <td style={{ width: "5%" }}>No.</td>
                                  <td style={{ width: "25%" }}>Categoría</td>
                                  <td style={{ width: "5%" }}>Estado</td>
                                  <td style={{ width: "35%" }}>Desv</td>
                                  <td style={{ width: "25%" }}>Acciones</td>
                                </tr>
                                {check.checks.map((check, idx3) => (
                                  <tr key={`check_${idx}_${idx2}_${idx3}`}>
                                    <td>{check.securityCheckType.number}</td>
                                    <td>{check.securityCheckType.name}</td>
                                    <td>
                                      {check.deviationValue === "TRUE" ? (
                                        <i className="fa fa-check text-success"></i>
                                      ) : check.deviationValue === "FALSE" ? (
                                        <i className="fa fa-times text-danger"></i>
                                      ) : (
                                        <i className="fa fa-clock text-secondary"></i>
                                      )}
                                    </td>
                                    <td>{check.value}</td>
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => setSelectedEvidences(check.files)}
                                      >
                                        <i className="fa fa-eye mx-2"></i>
                                        Ver evidencias
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ));
              })()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OperationDetails.propTypes = {
  shipping: PropTypes.object,
  groupEvidences: PropTypes.func,
  groupRevisions: PropTypes.func,
  setSelectedEvidences: PropTypes.func,
  selectedEvidences: PropTypes.array,
};

export default OperationDetails;
