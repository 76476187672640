import React from "react";
import { useEffect, useRef } from "react";
import View from "components/data_analytics/DataBI.view";

const DataBI = () => {

    const pathname = window.location.pathname;

    const containerRef = useRef(null);

    const urlsBi = {
        "/analytics/general_report": "https://app.powerbi.com/view?r=eyJrIjoiZjlhMDg5MmMtNTkyZi00YjA3LTk0MzYtODQ1ODdlZjBjNDQzIiwidCI6IjVhM2MyYTI2LWRmMTMtNDUyNC05ZGYyLTA3ZDlhNGQ0YTdkMCJ9&pageName=06aabbcd90dac8425fda&filterPaneEnabled=false&navContentPaneEnabled=false",
        "/analytics/crane_report": "https://app.powerbi.com/view?r=eyJrIjoiZjlhMDg5MmMtNTkyZi00YjA3LTk0MzYtODQ1ODdlZjBjNDQzIiwidCI6IjVhM2MyYTI2LWRmMTMtNDUyNC05ZGYyLTA3ZDlhNGQ0YTdkMCJ9&pageName=42fbc0c9b06beba5a625&navContentPaneEnabled=false&filterPaneEnabled=false",
        "/analytics/performance_report": "https://app.powerbi.com/view?r=eyJrIjoiZjlhMDg5MmMtNTkyZi00YjA3LTk0MzYtODQ1ODdlZjBjNDQzIiwidCI6IjVhM2MyYTI2LWRmMTMtNDUyNC05ZGYyLTA3ZDlhNGQ0YTdkMCJ9&pageName=8e1baa52339a11c3049a&navContentPaneEnabled=false&filterPaneEnabled=false"
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = (container.scrollHeight - container.clientHeight) / 2;
        }
    }, []);

    const urlBI = urlsBi[pathname]
    return <View urlBI={urlBI} containerRef={containerRef} />;
}

DataBI.propTypes = {};

export default DataBI;