import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PaginationFooter } from "seed/helpers";

const MaintenanceListView = ({
  maintenances,
  pageNum = 1,
  totalPages = 0,
  onClickPage = () => { },
  activeMaintenanceExist,
  calculateEvidencePercentage,
  updateStatus,
  redirectToCategories,
  redirectToResponsibles,
  deleteMaintenance,
  toggleSelectMaintenance
}) => {

  return (
    <div>
      <div style={{ height: "65vh", overflowY: "auto", overflowX: "hidden" }}>
        {maintenances.map((maintenance, idx) => (
          <div className="row border mx-1 text-reset" key={maintenance.id}
            style={{ background: idx % 2 == 0 ? "#fff" : "#f5f5f5" }}>
            {maintenance.isVisible}
            {/* Selección de mantenimiento (ícono de check) */}
            <div className="col-md-1 p-2">
              <i
                className={`fa ${maintenance.isVisible ? 'fa-check-circle' : 'fa-circle'} text-primary`}
                style={{ cursor: 'pointer', fontSize: '20px' }}
                onClick={() => toggleSelectMaintenance(maintenance.id, maintenance.isVisible)}
              ></i>
            </div>

            <div className="col-md-2 p-2">
              {maintenance?.crane?.series ? maintenance?.crane?.series : "SIN ASIGNAR"}
              {" / " + (maintenance?.crane?.model + maintenance?.crane?.number ? maintenance?.crane?.model + maintenance?.crane?.number : "SIN ASIGNAR")}
            </div>

            <div className="col-md-1 p-2 text-center">
              {maintenance.type == "NORMAL" ? "Normal" : maintenance.type == "FINAL" ? "Supervisor" : "Final"}
            </div>

            <div className="col-md-1 p-2 text-center">
              {calculateEvidencePercentage(maintenance)}%
            </div>

            <div className="col-md-1 p-2 text-center">
              {maintenance?.start ? (
                maintenance.start.split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")
              ) : "-"}
            </div>

            <div className="col-md-1 p-2 text-center">
              {maintenance?.end ? (
                maintenance.end.split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")
              ) : "-"}
            </div>

            <div className="col-md-1 p-2 text-center">
              <div
                className="custom-badge"
                style={function () {
                  if (maintenance.status == "ACTIVE") return { "backgroundColor": "#38538C" }
                  if (maintenance.status == "FINISHED") return { "backgroundColor": "#eb6859" }
                }()}
              >
                {function () {
                  if (maintenance.status == "ACTIVE")
                    return "Activo";
                  else if (maintenance.status == "FINISHED")
                    return "Terminado";
                }()}
              </div>
            </div>

            <div className="col-md-4 p-2 d-flex text-center justify-content-center">
              <button
                className="btn btn-secondary px-3 py-1 ml-9"
                onClick={() => redirectToCategories(maintenance.id)}
              >
                Ver evidencias
              </button>

              <button className="btn-light px-3 py-1 ml-2 btn-sm"
                onClick={
                  maintenance.status == "FINISHED"
                    ? activeMaintenanceExist(maintenance.crane.id)
                      ? () => alert("Ya existe un mantenimiento activo para esta grúa")
                      : () => updateStatus(maintenance.id, maintenance.status)
                    : () => updateStatus(maintenance.id, maintenance.status)
                }
                style={function () {
                  if (maintenance.status == "ACTIVE") return { "color": "#eb6859" }
                  if (maintenance.status == "FINISHED") return { "color": "#00A3BF" }
                }()}
              >
                {function () {
                  if (maintenance.status == "ACTIVE")
                    return "Marcar como terminado";
                  else if (maintenance.status == "FINISHED")
                    return "Activar";
                }()}
              </button>

              {/* Contenedor de íconos con ancho fijo */}
              <div className="icon-container d-flex justify-content-start align-items-center ml-2" style={{ minWidth: "120px" }}>
                <Link to={`/${maintenance.id}/print`} className="icon-link">
                  <i className="fa fa-clipboard text-warning"></i>
                </Link>
                {maintenance.type === "NORMAL" &&
                  <i
                    className="fa fa-users text-success ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectToResponsibles(maintenance.id)}
                  ></i>
                }
                <a className="text-danger ml-2" onClick={() => deleteMaintenance(maintenance.id)}>
                  <i className="fa fa-trash"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
    </div>
  );
};

MaintenanceListView.propTypes = {
  maintenances: PropTypes.array.isRequired,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPage: PropTypes.func,
  activeMaintenanceExist: PropTypes.func,
  updateStatus: PropTypes.func,
  redirectToCategories: PropTypes.func,
  redirectToResponsibles: PropTypes.func,
  deleteMaintenance: PropTypes.func,
};

export default MaintenanceListView;
