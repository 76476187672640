import React from "react";
import PropTypes from "prop-types";
import { PaginationFooter } from "seed/helpers";
import { Link } from "react-router-dom";

const SecurityRevisionListView = ({
  pageNum = 1,
  orderDate,
  totalPages = 0,
  onClickPage = () => {},
  deleteSecurityRevision,
  groupedRevisions = {},
  calculatePercentage,
  getPercentageColor
}) => {
  // Convertir groupedRevisions a una lista y ordenarla
  const sortedRevisions = Object.values(groupedRevisions)
    .map(group => {
      const initialRevision = group.INITIAL;
      const finalRevision = group.FINAL;
      const createdAt = initialRevision?.createdAt || finalRevision?.createdAt;
      return {
        ...group,
        createdAt: new Date(createdAt)
      };
    });

  if (orderDate === '-') {
    sortedRevisions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 
  } else {
    sortedRevisions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)); 
  }

  return (
    <div>
      <div style={{ height: "65vh", overflowY: "auto", overflowX: "hidden" }}>
        {sortedRevisions.map((group, idx) => {
          const initialRevision = group.INITIAL;
          const finalRevision = group.FINAL;
          const revisionToDisplay = initialRevision || finalRevision;
          const initialPercentage = initialRevision ? calculatePercentage(initialRevision) : 0;
          const finalPercentage = finalRevision ? calculatePercentage(finalRevision) : 0;
          const idToUse = revisionToDisplay?.bothId && (revisionToDisplay.bothId !== "" && revisionToDisplay.bothId !== "now") ? revisionToDisplay.bothId : revisionToDisplay.id;

          return (
            <div key={idToUse} className="row border mx-1 text-reset" style={{ background: idx % 2 === 0 ? "#fff" : "#f5f5f5" }}>
              <div className="col-md-2 p-2 text-center">
                {revisionToDisplay?.order 
                  ? (revisionToDisplay?.order?.crane 
                      ? `${revisionToDisplay.order.crane.series} / ${revisionToDisplay.order.crane.model} ${revisionToDisplay.order.crane.number}` 
                      : `SIN ASIGNAR / ${revisionToDisplay.order.modelReference}`)
                  : (revisionToDisplay?.crane 
                      ? `${revisionToDisplay.crane.series} / ${revisionToDisplay.crane.model} ${revisionToDisplay.crane.number}` 
                      : "Información de la grúa no disponible")}
              </div>
              <div className="col-md-2 p-2 text-center">
                {revisionToDisplay?.order?.buildingName}
              </div>
              <div className="col-md-2 p-2 text-center">
                {(new Date(revisionToDisplay?.createdAt)).toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit", year: "numeric" })}
              </div>
              <div className="col-md-2 p-2 text-center" style={{ color: getPercentageColor(initialPercentage) }}>
                {initialRevision ? `${initialPercentage}%` : finalRevision ? '-' : '0%'}
              </div>
              <div className="col-md-2 p-2 text-center" style={{ color: getPercentageColor(finalPercentage) }}>
                {finalRevision ? `${finalPercentage}%` : initialRevision ? '-' : '0%'}
              </div>
              <div className="col-md-2 p-2">
                <div className="row">                        
                  <Link to={`/${idToUse}/details`}>
                    <button className="btn-secondary px-3 py-1 ml-6">
                      Ver evidencias
                    </button>
                  </Link>
                  {revisionToDisplay?.order && (
                    <Link to={`/${idToUse}/edit`} className="ml-3 mt-1">
                      <i className="fa fa-edit text-primary"></i>
                    </Link>
                  )}
                  <div className="col mt-1">
                    <a className="text-danger" role="button" onClick={() => { deleteSecurityRevision(idToUse) }}>
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
    </div>
  );
};

SecurityRevisionListView.propTypes = {
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPage: PropTypes.func,
  deleteSecurityRevision: PropTypes.func,
  groupedRevisions: PropTypes.object,
  calculatePercentage: PropTypes.func,
  getPercentageColor: PropTypes.func
};

export default SecurityRevisionListView;
