import React from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/Form.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import swal from "sweetalert";
import { usePost } from "seed/api";
import { useHistory } from "react-router-dom";


const FormSave = () => {

    const history = useHistory();

    const[callSave, setCallSave] = usePost("/preventive_maintenances/create_maintenance", {
        onCompleted : (data) => {
            if(data.value_response === -1){
                swal("¡Error!", "Ya existe un mantenimiento preventivo para esta orden.", "error").then(() => {
                    window.location.reload();
                });
            } else if(data.value_response === 1){
                history.goBack();
                swal("¡Éxito!", "Mantenimiento preventivo creado exitosamente", "success").then(() => {
                    window.location.reload();
                });
            } else {
                swal("¡Error!", "Error al crear mantenimiento preventivo", "error").then(() => {
                    window.location.reload();
                });
            }
        },
        onError : () => {
            swal("¡Error!", "Error al crear mantenimiento preventivo", "error").then(() => {
                window.location.reload();
            });
        }
    });

    const qUsers = useQuery(`{
        users{
            firstName
            lastName
        }
    }`, "rol=TECHNICIAN");

    const qOrders = useQuery(`{
        orders{
            buildingName
            crane{
                model
                series
                number
            }
        }
    }`);

    if (qUsers.loading) return <Loading />;
    if (qUsers.error) return <Error />;
    if (qOrders.loading) return <Loading />;
    if (qOrders.error) return <Error />;

    const { users = [] } = qUsers.data;
    const { orders = [] } = qOrders.data;

    const ordersFilters = orders.filter(order => order.crane);

    const onSubmit = (values) => {
        if(!values.order) swal("¡Error!", "Debe seleccionar una orden", "error");
        
        callSave(values);
    };

    return <View    
            users={users} 
            orders={ordersFilters} 
            onSubmit={onSubmit} />;
}

FormSave.propTypes = {};

export default FormSave;