import React from "react";
import PropTypes from "prop-types";

const DataBIView = ({ urlBI, containerRef }) => {
    const iframeHeight = window.innerWidth >= 768 ? `calc(100vh - 36px)` : `calc(50vh - 36px)`; // Ajuste de altura para ocultar la barra

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f7f7f7",
            overflow: "hidden"
        }}>
            <div
                ref={containerRef}
                style={{
                    width: "110vw",
                    height: "100vh",
                    backgroundColor: "#f7f7f7",
                    overflow: "hidden"
                }}>
                <iframe
                    className="mx-auto"
                    style={{
                        width: "100%",
                        border: "none",
                        background: "#f7f7f7",
                        height: iframeHeight,
                        overflow: "hidden",
                        clipPath: "inset(0px 0px 36px 0px)" 
                    }}
                    title="Panel"
                    src={urlBI}
                />
            </div>
        </div>
    );
};

DataBIView.propTypes = {
    urlBI: PropTypes.string.isRequired,
    containerRef: PropTypes.object
};

export default DataBIView;
