import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

const FormView = ({
    parts, 
    onSubmit,
    onClose,
    error, 
    step }) =>
    <div className="card h-100">

        {/* Header */}
        {step === 1 && (
            <>
                <div className="card-header">
                    <h3 className="card-header-title">
                        {"Nueva excepción"}
                    </h3>
                </div>
            </>
        )}

        {/* Body */}
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <Formik
                        initialValues={{}}
                        onSubmit={onSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {step === 1 && (
                                    <>
                                        <div className="mb-3">

                                            <div className="row">
                                                {/* Pieza */}
                                                <div className="col-md-6">
                                                    <div class="form-group form-group-default">
                                                        <div>
                                                        <label class="input-label">Pieza</label>
                                                        <Typeahead
                                                            id="menu"
                                                            placeholder="Buscar pieza"
                                                            labelKey={(part) => `${part?.name} ${part?.partId} - ${part?.craneModel}` }
                                                            onChange={(selected) => selected.length > 0 ? setFieldValue("part", selected[0].id) : ""}
                                                            options={parts}
                                                            required
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {/* Cantidad */}
                                                <div className="col-md-6">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Cantidad</label>
                                                        <Field
                                                            type="number"
                                                            name="quantity"
                                                            id="quantity"
                                                            className="form-control"
                                                            required
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                {/* Motivo de  la excepción */}
                                                <div className="col-md-12">
                                                    <div className="form-group form-group-default required">
                                                        <label className="control-label">Motivo de la excepción</label>
                                                        <textarea
                                                            type="textarea"
                                                            name="reason"
                                                            id="reason"
                                                            rows="5"
                                                            className="form-control"
                                                            required
                                                        />
                                                        <div className="invalid-feedback">Requerido</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Botones de acción */}
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-block btn-primary">
                                                    {"Crear excepción"}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <div className="alert alert-success" role="alert">
                                            {error === null ? "Pieza actualizada correctamente" : error}
                                        </div>
                                        <button className="btn btn-block btn-primary" onClick={onClose}>
                                            Listo
                                        </button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
    ;

FormView.propTypes = {};

export default FormView;